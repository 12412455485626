import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "#89CFF0",
        color: "black",
        py: { xs: 4, md: 6 },
        mt: "auto",
        overflowX: "hidden"
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="center">
          {/* Quick Links */}
          <Grid item xs={12} sm={6} md={4} sx={{ textAlign: { xs: "center", md: "left" } }}>
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            <Link to="/faq" style={{ textDecoration: "none", color: "inherit" }}>FAQ</Link>
            <br />
            <Link to="/privacy-policy" style={{ textDecoration: "none", color: "inherit" }}>Privacy Policy</Link>
            <br />
            <Link to="/terms-and-conditions" style={{ textDecoration: "none", color: "inherit" }}>Terms And Conditions</Link>
          </Grid>

          {/* Address */}
          <Grid item xs={12} sm={6} md={4} sx={{ textAlign: { xs: "center", md: "right" } }}>
            <Typography variant="h6" gutterBottom>
              Address
            </Typography>
            <Typography variant="body2">
              Plot No C-9, Asha Officers Colony
              <br />
              RK Puram, Secunderabad, Hyderabad
              <br />
              Phone: (9595027710)
              <br />
              Email: pthakur@arthgyan.com
            </Typography>
          </Grid>
        </Grid>

        {/* Disclaimer & Copyright */}
        <Box sx={{ textAlign: "center", mt: 5, px: { xs: 2, sm: 0 }, overflowX: "hidden" }}>
          <Typography variant="body2" color="text.secondary">
            Mutual Funds investments are subject to market risks. Read all scheme related documents carefully.
            Past performance is not an indicator for future returns.
            <br />
            GST No: 36ABACA5696C1ZP
            <br />
            Arthagyani Technology Pvt.Ltd CIN - U62099TS2024PTC184726.
            <br />
            AMFI Registration Number (ARN): 320330
            <br />
            <br />
            * Registered with the Association of Mutual Funds in India as Mutual Fund Distributor.
            <br />
            * Investment in the securities market is subject to market risks, read all the related documents carefully before investing.
            Brokerage or Fee will not exceed the SEBI prescribed limit. The privacy policy of the website is applicable.
            <br />
            For any complaints email at pthakur@arthgyan.com. Please ensure you carefully read the Risk Disclosure Document as prescribed
            by SEBI and our Terms of Use and Privacy Policy.
            <br />
          </Typography>
          <Typography variant="body2" sx={{ color: "black", fontWeight: "bold", mt: 2 }}>
            © {new Date().getFullYear()} Arthagyani Technology Pvt. Ltd. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
