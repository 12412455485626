import React, { useEffect, useState } from "react";
import {
    Container,
    Typography,
    Button,
    Box,
    Grid,
    List,
    ListItem,
    ToggleButton,
    ToggleButtonGroup,
} from "@mui/material";
import { styled } from "@mui/system";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import Footer from "./Footer";
import Navbar from "./Navbar";
import creditCardData from "../JSON/creditCardData.json";
import { useParams } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const StyledHero = styled(Box)(({ theme, backgroundImage }) => ({
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${backgroundImage}')`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(6, 2),
    textAlign: "center",
    height: "300px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
}));

function CreditCardsPage() {
    const { id } = useParams();

    const [activeSection, setActiveSection] = useState("description");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const selectedCard = creditCardData.find((card) => card.id === id);

    if (!selectedCard) {
        return (
            <Typography variant="h4" sx={{ textAlign: "center", marginTop: 4 }}>
                Credit Card Data Not Found
            </Typography>
        );
    }

    const { hero, card, applyNowUrl } = selectedCard;

    const handleApplyNow = () => {
        window.open(applyNowUrl, "_blank", "noopener,noreferrer");
    };

    return (
        <>
            <StyledHero backgroundImage={hero.backgroundImage}>
                <Navbar />
                <Container maxWidth="xl">
                    <Typography
                        variant="h2"
                        component="h1"
                        gutterBottom
                        sx={{
                            fontWeight: "bold",
                            marginBottom: "1rem",
                            fontSize: { xs: "1.8rem", sm: "2.5rem", md: "3rem" },
                        }}
                    >
                        {hero.title}
                    </Typography>
                    <Typography
                        variant="h5"
                        paragraph
                        sx={{
                            marginBottom: "1.5rem",
                            fontSize: { xs: "1rem", sm: "1.2rem", md: "1.5rem" },
                        }}
                    >
                        {hero.subtitle}
                    </Typography>
                </Container>
            </StyledHero>

            <Box sx={{ paddingX: 2, marginTop: -6 }}>
                <Box
                    sx={{
                        maxWidth: "400px",
                        margin: "0 auto",
                        padding: 2,
                        backgroundColor: "#d7eeff",
                        borderRadius: "16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Box
                        component="img"
                        src={card.image}
                        alt={hero.title}
                        sx={{
                            maxWidth: "100%",
                            height: "auto",
                            objectFit: "contain",
                            display: "block",
                            borderRadius: "8px",
                        }}
                    />
                </Box>
            </Box>

            <Box sx={{ padding: 3 }}>
                <Typography
                    variant="h4"
                    gutterBottom
                    sx={{
                        textAlign: "center",
                        marginBottom: 3,
                        fontSize: { xs: "1.8rem", sm: "2rem" },
                        fontWeight: "bold",
                    }}
                >
                    Card Features
                </Typography>
                <Grid container spacing={2} justifyContent="center">
                    {card.features.map((feature, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "#f4f4f4",
                                    borderRadius: 3,
                                    padding: 2,
                                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                                }}
                            >
                                <CheckCircleOutlineIcon
                                    color="primary"
                                    sx={{ marginRight: 2 }}
                                />
                                <Typography variant="body1">{feature}</Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
                <Box sx={{ textAlign: "center", marginTop: "2rem" }}>
                    <Button
                        variant="contained"
                        onClick={handleApplyNow}
                        sx={{
                            width: { xs: "100%", sm: "50%", md: "30%" },
                            padding: "10px 30px",
                            borderRadius: 5,
                            fontSize: "1rem",
                        }}
                    >
                        Apply Now
                    </Button>
                </Box>
            </Box>

            <Box sx={{ textAlign: "center", marginTop: 4 }}>
                <ToggleButtonGroup
                    value={activeSection}
                    exclusive
                    onChange={(e, newSection) => setActiveSection(newSection)}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        gap: 2,
                    }}
                >
                    {["description", "eligibility", "charges"].map((section) => (
                        <ToggleButton
                            key={section}
                            value={section}
                            sx={{
                                fontWeight: "bold",
                                padding: "15px 30px",
                                fontSize: "1rem",
                                textTransform: "none",
                                "&.Mui-selected": {
                                    textDecoration: "underline",
                                    color: "primary.main",
                                },
                                "&:hover": {
                                    backgroundColor: "transparent",
                                },
                            }}
                        >
                            {section.charAt(0).toUpperCase() + section.slice(1)}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </Box>

            {activeSection === "description" && card.description && (
                <Box sx={{ padding: 3 }}>
                    <Typography variant="h5" gutterBottom>
                        Description
                    </Typography>
                    <List>
                        {card.description.map((item, index) => (
                            <ListItem key={index}>
                                <FiberManualRecordIcon
                                    fontSize="small"
                                    sx={{ color: "primary.main", marginRight: 1 }}
                                />
                                <Typography>{item}</Typography>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            )}
  {activeSection === "eligibility" && card.eligibility && (
                <Box sx={{ padding: 3 }}>
                    <Typography variant="h5" gutterBottom>
					Eligibility
                    </Typography>
                    <List>
                        {card.eligibility.map((item, index) => (
                            <ListItem key={index}>
                                <FiberManualRecordIcon
                                    fontSize="small"
                                    sx={{ color: "primary.main", marginRight: 1 }}
                                />
                                <Typography>{item}</Typography>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            )}
			  {activeSection === "charges" && card.chargesAndFees && (
                <Box sx={{ padding: 3 }}>
                    <Typography variant="h5" gutterBottom>
					Charges & Fees
                    </Typography>
                    <List>
                        {card.chargesAndFees.map((item, index) => (
                            <ListItem key={index}>
                                <FiberManualRecordIcon
                                    fontSize="small"
                                    sx={{ color: "primary.main", marginRight: 1 }}
                                />
                                <Typography>{item}</Typography>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            )}
            {/* Add similar conditional rendering for 'eligibility' and 'charges' sections */}
            <Footer />
        </>
    );
}

export default CreditCardsPage;
