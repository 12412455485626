import React from "react";
import {
  Box,
  Typography,
  Container,
  Paper,
  Divider,
  CssBaseline,
} from "@mui/material";

const TermsAndConditions = () => {
  return (
    <>
    <CssBaseline />
     <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
   
  <p>
    <strong>Terms and Conditions</strong>
    <strong>
      <br />
    </strong>
    <strong>&nbsp;</strong>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>The Mobile Application ‘</span>
    <strong>ArthGyan</strong>
    <span style={{ fontWeight: 400 }}>' (hereinafter referred to as “</span>
    <strong>Application</strong>
    <span style={{ fontWeight: 400 }}>") and website </span>
    <a href="http://www.arthgyan.com">
      <span style={{ fontWeight: 400 }}>www.arthgyan.com</span>
    </a>
    <span style={{ fontWeight: 400 }}> is owned and operated by '</span>
    <strong>Arthagyani Technology Private Limited</strong>
    <span style={{ fontWeight: 400 }}>
      ’ a company incorporated under the Companies Act, 2013 with its registered
      office at Plot No C-9, Asha Officers Colony, Ramakrishna Puram,
      Tirumalagiri, Hyderabad- 500056, Telangana.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      Arthagyani Technology Private Limited (“
    </span>
    <strong>ArthGyan</strong>
    <span style={{ fontWeight: 400 }}>
      ") is in the business of distribution of financial products through its
      mobile technology platform and website. ArthGyan requests you to carefully
      go through these terms &amp; conditions ("Terms &amp; Conditions") to
      avail the access to the distribution network of ArthGyan through its
      mobile technology platform and website. If you continue to browse and use
      this Application and website, you irrevocably and unconditionally are
      agreeing to comply with, abide by and be bound by all the obligations as
      stipulated in the following terms and conditions of use, which together
      with our privacy policy and any other applicable policies which are
      incorporated herein by way of reference or available by hyperlink, shall
      govern ArthGyan's relationship with you in relation to this application.
      These Terms &amp; Conditions supersede all previous oral and written terms
      and conditions (if any) communicated to you and shall act as an Agreement
      between ArthGyan and Users. It is further clarified that the Company
      reserves the right to execute any additional separate agreement with the
      Users (including the sub-brokers) with respect to any specific terms and
      conditions and/or commercials and thus, these Terms and Conditions, and
      such additional agreement(s) and the Platform Agreement shall be read and
      construed together.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>&nbsp;</span>
  </p>
  <p>
    <strong>
      THE USE OF THIS APPLICATION IS SUBJECT TO THE FOLLOWING Terms &amp;
      Conditions:
    </strong>
  </p>
  <p>
    <strong>Definitions</strong>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>"</span>
    <strong>User</strong>
    <span style={{ fontWeight: 400 }}>", "</span>
    <strong>You/you</strong>
    <span style={{ fontWeight: 400 }}>", "</span>
    <strong>Your/your</strong>
    <span style={{ fontWeight: 400 }}>
      " means any person or user or Registered user or viewer or Registered
      Customer who views, browses, accesses or uses our ArthGyan;
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>"</span>
    <strong>Use</strong>
    <span style={{ fontWeight: 400 }}>" or "</span>
    <strong>Using</strong>
    <span style={{ fontWeight: 400 }}>
      " means to browse, access, view, copy, availing Services or other benefit
      from using the Application and website;
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>“</span>
    <strong>ArthGyan</strong>
    <span style={{ fontWeight: 400 }}>" or "</span>
    <strong>us</strong>
    <span style={{ fontWeight: 400 }}>" or "</span>
    <strong>we</strong>
    <span style={{ fontWeight: 400 }}>" or "</span>
    <strong>our</strong>
    <span style={{ fontWeight: 400 }}>
      " shall mean and refer to Arthagyani Technology Private Limited and its
      affiliates, officers, employees, agents, partners and licensors.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>"</span>
    <strong>AMC</strong>
    <span style={{ fontWeight: 400 }}>
      " shall mean and include Asset Management Company listed on BSE.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>"</span>
    <strong>BSE</strong>
    <span style={{ fontWeight: 400 }}>" shall mean Bombay Stock Exchange.</span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>"</span>
    <strong>Services</strong>
    <span style={{ fontWeight: 400 }}>
      " means all the services as provided in Terms &amp; Conditions from time
      to time.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>"</span>
    <strong>Force Majeure Event</strong>
    <span style={{ fontWeight: 400 }}>
      " means any event due to any cause beyond the reasonable control of
      ArthGyan, including without limitations, unavailability of any
      communication systems, breach, or virus in the processes or payment or
      delivery mechanism, sabotage, fire, flood, explosion, acts of god, civil
      commotion, strikes or industrial action of any kind, riots, insurrection,
      war, acts of government, computer hacking, unauthorised access to computer
      data and storage devices, computer crashes, malfunctioning in the computer
      terminal or the systems getting affected by any malicious, destructive or
      corrupting code or program, mechanical or technical errors/failures or
      power shut down, faults or failures in telecommunication etc.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>“</span>
    <strong>Application</strong>
    <span style={{ fontWeight: 400 }}>" or “</span>
    <strong>Mobile Application</strong>
    <span style={{ fontWeight: 400 }}>
      " means ArthGyan, the mobile Application accessed through or downloaded
      from the Play store or App store i.e. Android or IOS.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>"</span>
    <strong>RTA</strong>
    <span style={{ fontWeight: 400 }}>
      " shall mean Registrar and Transfer Agent.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>"</span>
    <strong>Registered User</strong>
    <span style={{ fontWeight: 400 }}>" or "</span>
    <strong>Registered Customer</strong>
    <span style={{ fontWeight: 400 }}>
      " shall mean and include the users or customers who have Investment
      Account with ArthGyan to avail the Services or any part thereof, as
      provided on the Application.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>"</span>
    <strong>SIP</strong>
    <span style={{ fontWeight: 400 }}>
      " shall mean Systematic Investment Plans
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>"</span>
    <strong>STP</strong>
    <span style={{ fontWeight: 400 }}>
      " shall mean Systematic Transfer Plans
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>"</span>
    <strong>NAV</strong>
    <span style={{ fontWeight: 400 }}>" shall mean Net Asset Value</span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>"</span>
    <strong>Account</strong>
    <span style={{ fontWeight: 400 }}>
      " shall mean the account opened with ArthGyan by the Registered Users, for
      use of the Application or for making investments through the Application.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>“</span>
    <strong>Platform Agreement</strong>
    <span style={{ fontWeight: 400 }}>
      ” shall mean the Technology Platform Usage Services Agreement entered into
      between the Company and the Partner (as defined therein) with respect to
      use of the Company’s platform by the Partner to provide distribution
      services relating to Mutual Funds to the advisees or customers of the
      Partner.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>Application use:</strong>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      User acknowledges that in accessing and using the Services offered by
      ArthGyan, user may utilize the content and Services offered on the
      ArthGyan Application. User agrees to follow the terms and conditions for
      the use of the Application as may be specified by ArthGyan from time to
      time. Notwithstanding such acceptance of the ArthGyan usage terms, User
      acknowledges and agrees to the following, that:
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      Login Credentials should be kept safe and secure to prevent unauthorized
      access to your ArthGyan account. If you think that the security of your
      ArthGyan account has been compromised, change your password and contact us
      immediately for further assistance, on the following e-mail:&nbsp;
    </span>
    <strong>support @arthgyan.com</strong>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      - ArthGyan (Arthagyani Technology Private Limited) is an AMFI registered
      distributor and advisor of mutual funds (ARN-320330) and enables Users to
      purchase units of various mutual funds, details of which shall be made
      available on the Application, from time to time.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      - The investment account of the user will be activated after ArthGyan
      completes the verification process on the personal information provided at
      the time of enrolment and in accordance with the Know Your Client ("
    </span>
    <strong>KYC</strong>
    <span style={{ fontWeight: 400 }}>
      ") guidelines issued by the Securities and Exchange Board of India ("
    </span>
    <strong>SEBI</strong>
    <span style={{ fontWeight: 400 }}>").</span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      - User shall not to copy, reproduce, sell, redistribute, publish, enter
      into a database, display, perform, modify, transmit, license, create
      derivatives from, transfer or in any way exploit any part of any
      information, content, materials, services available from or through the
      Application, except that which user may download for personal,
      non‐commercial use.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      - User will not use ArthGyan’s Applications for any purpose that is
      unlawful, or prohibited by these Terms. User also agrees that user will
      not use the Applications in any manner that could damage, disable or
      impair the Application or interfere with any other person’s use of
      ArthGyan’s applications.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      - The software and hardware underlying the Applications as well as other
      internet related software which are required for accessing the
      Applications are the legal property of the respective vendors. The
      permission given by ArthGyan to access the application will not convey any
      proprietary or ownership rights in the above software/ hardware. User
      agrees that User shall not attempt to modify, translate, disassemble,
      decompile or reverse engineer the software/hardware underlying the
      Application or create any derivative product based on the software /
      hardware.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      - ArthGyan is not responsible for the availability of content or other
      services on third party sites linked from the ArthGyan’s Applications.
      User agrees and is aware that access of hyperlinks to other internet sites
      are at users own risk and the content, accuracy, opinions expressed, and
      other links provided by these sites are not verified, monitored or
      endorsed by ArthGyan in any way. ArthGyan does not make any warranties and
      expressly disclaims all warranties express or implied, including without
      limitation, those of merchantability and fitness for a particular purpose,
      title or non-infringement with respect to any information or services or
      products that are available or advertised or sold through these third
      party websites.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      - ArthGyan has the absolute discretion to amend or supplement any of the
      ArthGyan Application Terms at any time without prior notice for such
      changes. Any such amendment shall be effective immediately. It shall be
      Users responsibility to review these Terms and Conditions periodically for
      any updates/changes. User agrees that the information and assistance
      provided on the ArthGyan Application are true to the knowledge of ArthGyan
      and should not be construed as investment advice, and that ArthGyan shall
      have no liability for any discrepancy in any such information or
      assistance.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      - Mere use of the Application, shall be construed as your intent to
      contract with us and shall constitute your binding obligations, with us.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      - User understands that it is users responsibility to check the ArthGyan
      Application's Terms carefully before accessing or using or transacting on
      our Application.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      - As long as User complies with these Terms and Conditions, we grant user
      a personal, non-exclusive, non-transferable, limited privilege to enter
      and use the Application. User acknowledges and understands that use of the
      Application shall be at your own risk and has the discretion of not using
      the Application.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      - ArthGyan shall not be liable if any transaction does not fructify or may
      not be completed or for any failure on part of the Application to perform
      any of its obligations under these terms and conditions or those
      applicable specifically to its services/facilities if performance is
      prevented, hindered or delayed by a Force Majeure event and in such case
      its obligations shall be suspended for so long as the Force Majeure event
      continues.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      - All information submitted by user to ArthGyan (except for the personal
      information which shall be by virtue of its nature, be deemed to be
      proprietary information of the User) whether through the ArthGyan
      Applications or otherwise shall be deemed to be the property of ArthGyan,
      and ArthGyan shall be free to use any ideas, concepts, know-how or
      techniques provided by such users at the ArthGyan application, in any
      manner whatsoever.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      - User expressly authorizes ArthGyan to collect, process, and share
      details of user's insurance policy holdings and other financial products
      with the user's Mutual Fund Distributor. The aforementioned data sharing
      is intended solely to enable the Mutual Fund Distributor to offer more
      personalized and effective incidental advice to the user.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      - Not all the products and services offered on Application are available
      in all geographic areas and User may not be eligible for all the products
      or services offered by ArthGyan on the Application. ArthGyan reserves the
      right to determine the availability and eligibility for any product or
      service.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>AVAILING OF SERVICES</strong>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      Service(s) shall be availed in accordance with the Terms and Conditions
      mentioned herein. User agrees that ArthGyan and / or the mutual fund may
      at its sole discretion vary the terms and conditions or withdraw any of
      the facilities provided herein from time to time. Further information is
      elaborated under “
    </span>
    <strong>Termination of Service</strong>
    <span style={{ fontWeight: 400 }}>"</span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      User hereby irrevocably and unconditionally grants no objection to
      ArthGyan and the respective mutual funds / RTAs to collate the transaction
      details relating to the investments in mutual fund units done by user on
      the online technology platform of ArthGyan and provide such transaction
      data to ArthGyan for further processing of user transactions.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>REGISTRATION OF THE USER</strong>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      Access to ArthGyan's Services will be granted by ArthGyan only to
      Registered Customers of ArthGyan, post the enrolment for the Services.
      User agrees and acknowledges that the Services provided herein are
      presently available in respect of select Mutual Funds only with whom
      ArthGyan has entered into a separate arrangement. User shall provide the
      required details and the documents for the purpose of the registration and
      enrollment. For the purpose of registering on the Application:
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      A valid phone number has to be provided and a password has to be created.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>
      For the purpose of enrollment and using the Services of ArthGyan, the User
      has to provide the following details:
    </strong>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Pan Card details and a copy of the PAN card
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Bank Account Details and a cancelled cheque leaf
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>Address proof</span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>Photograph</span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>Signature</span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      The above provided information/details shall be uploaded by the User in
      soft copy form on the Application at the time of enrollment. Once the
      information is received by ArthGyan, the executive of ArthGyan shall
      co-ordinate with the User, for obtaining an attestation from the
      Registered Users, if required. Upon completion of the attestation
      requirements, the documents shall be provided to the concerned regulatory
      authority/mutual fund company for the purpose of opening an account with
      ArthGyan for the transaction to be made by the Users.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      During the registration process, the customer will be prompted to sign on
      the mobile screen. ArthGyan will capture the signature of the customer and
      will utilize only for the purpose of completing the registration
      formalities – namely, KYC form and BSE Account Opening Form. The signature
      does not allow ArthGyan to undertake any transactions other than specified
      here.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>Kindly Note:</strong>
    <span style={{ fontWeight: 400 }}>
      &nbsp;In the event, if any discrepancy is found, in any of the information
      provided by the User, for example, information in any of the documents
      provided does not match with the adjoining documents, such mismatch shall
      lead to the rejection of the documents. The privacy of the documents
      provided or any such personally identifiable information provided to
      ArthGyan, shall be maintained in accordance with the privacy policy of the
      Application.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      User agrees that any and / or all information that may be provided by you
      to ArthGyan from time to time, including but not limited to contact
      information, address and tax related information or any other information
      required under existing or future KYC or other norms and laws, may be
      shared by ArthGyan with the regulatory authorities/mutual funds/BSE/AMCs
      or their respective authorized service providers, auditors, legal and tax
      consultants in compliance with the extant legal provisions from time to
      time.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      There may be an exit load applicable to certain mutual fund schemes which
      is mentioned in the respective offer documents including Scheme
      Information Document (SID) /Key Information Memorandum (KIM) and addendums
      issued thereto from time to time (collectively referred to as "
    </span>
    <strong>scheme related documents</strong>
    <span style={{ fontWeight: 400 }}>
      "). User shall read all the scheme related documents before making any
      transaction on ArthGyan.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      Investments from persons from the country other than India may not be
      permitted in certain mutual funds. ArthGyan/ BSE/their respective trustee
      companies shall not be liable for rejection of an application by mutual
      funds, where the investor is a person from a country other than India or
      such other prohibited investor as may be specified in the respective
      scheme related documents from time to time.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      Purchase requests made through ArthGyan's online technology platform shall
      be processed by the respective mutual funds only after funds sufficient to
      cover the purchase price and other costs and charges have been received by
      the respective mutual funds. Payments towards the purchase price and other
      costs and charges shall be made by the Registered Customers through NEFT
      or the payment gateway or other electronic means made available by
      ArthGyan on the online technology platform. If for any reason the mutual
      fund is unable to allow a transaction for purchase or redemption of the
      full quantity of units such as transacted by a Registered Customer through
      the online technology platform of ArthGyan, the respective mutual fund
      shall be entitled to process a lesser quantity of units being purchased or
      redeemed (as the case may be) by such Registered Customer. In such a case,
      ArthGyan shall be not responsible for the non-execution of transactions
      for the entire quantity or the remaining quantity of units.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      Transaction rights for SIPs and STPs will be pursuant to the Registered
      Customer executing the instructions, in the manner and form as prescribed
      by ArthGyan and / or mutual funds on the online technology platform from
      time to time. The instructions will be applicable to all SIP, STP
      transactions whether presently existing or to be opened in future. User
      agrees that, upon granting such instructions for SIP, STP transactions,
      User will be bound by the terms of the relevant SIP and STP scheme of the
      mutual fund to which User subscribes.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      User agrees and acknowledges that any transaction done or purported to be
      done by User on a business day would be processed on the next business
      day. The User will be allotted Mutual Fund units by the respective AMC
      based on the NAV applicable of that business day. This will be applicable
      to switch and redemption transactions also.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      While ArthGyan shall make every effort to ensure adherence to the above
      timelines, ArthGyan shall in no way be responsible or held liable for any
      delay thereof, including for loss of interest and / or opportunity loss
      and / or any loss arising due to movement in NAV or any other losses,
      liabilities, damages, costs, charges, expenses which the investor(s) may
      sustain, incur or suffer or be put to or become liable or incur directly
      or indirectly by reason or as a consequence of any delay thereof.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      User acknowledges that the units of the scheme shall be allotted, redeemed
      or switched, as the case may be, by the respective mutual funds at the
      applicable NAV of the concerned mutual fund scheme as provided by mutual
      funds and consistent with the terms of the Scheme. However, ArthGyan shall
      not be liable for any loss that may arise to user as a result of incorrect
      NAV applied on units allotted to user by the mutual fund.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      User acknowledges that any transactions done on a holiday would be
      processed on the next business day and the NAV would be applicable as per
      the respective scheme related documents.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      User acknowledges that transactions once placed cannot be cancelled.
      However, user can withdraw money by redeeming the mutual fund units. Upon
      such withdrawal, the settlement amount towards the mutual fund or the
      units of Mutual fund shall be credited to the registered account of the
      user within 3-4 bank working days.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      User acknowledges that any directions pertaining to all transactions
      including withdrawal, STP and switch transactions provided by the User
      from its Registered User account shall be deemed to be considered as the
      bona-fide order placed by the User.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      User have read and understood the contents of the Scheme related Document
      and the details of the scheme and they have not received or been induced
      by any rebate or gifts, directly or indirectly, in making investment. User
      hereby declare that the amount invested/to be invested by their investment
      in the scheme(s) of Mutual Fund (s) is derived through legitimate sources
      and is not held or designed for the purpose of contravention of any act,
      rules, regulations or any statute or legislation or any other applicable
      laws or any notifications, directions issued by any governmental or
      statutory authority from time to time.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      The AMFI Registration Number ("ARN") holder has disclosed to user all the
      commissions (in the form of trail commission or any other mode), payable
      to ARN holder for the different competing Schemes of various Mutual Funds
      from amongst which the Scheme is being recommended to users.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>LINK TO THIRD PARTY</strong>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      ArthGyan may provide you links to third party services ("Third Party
      Services"). You agree to use the Services at your sole risk and that we
      shall not have any liability to you for content that may be found to be
      offensive, indecent, or objectionable. User understands that certain
      Services may display, include or make available content, data,
      information, applications or materials from third parties ("Third Party
      Materials") or provide links to certain third party websites. By using the
      Third Party Services, you acknowledge and agree that ArthGyan is not
      responsible for examining or evaluating the content, accuracy,
      completeness, timeliness, validity, copyright compliance, legality,
      decency, quality or any other aspect of such Third Party Materials or
      websites. ARTHGYAN DOES NOT WARRANT OR ENDORSE AND DOES NOT ASSUME AND
      WILL NOT HAVE ANY LIABILITY OR RESPONSIBILITY TO YOU OR ANY OTHER PERSON
      FOR ANY THIRD PARTY SERVICES, THIRD PARTY MATERIALS OR WEB SITES, OR FOR
      ANY OTHER MATERIALS, PRODUCTS, OR SERVICES OF THIRD PARTIES.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>NO OBEJECTION FROM THE USER(S)</strong>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      User acknowledges and grants no objection to ArthGyan to do or place
      transaction requests for the User on the online technology platform of
      ArthGyan and at users own risk and cost, all or any of the following acts,
      deeds matters and things:
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      To forward User transaction data and requests to the respective mutual
      funds/BSE with respect to the instructions and orders of sale, purchase,
      transfer, splitting and consolidation of mutual funds units.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      To transmit to the mutual fund and/or the BSE, information relating to
      User nomination/changes in investment plan/ any other changes made through
      the online platform.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      To obtain and forward to the BSE/AMC, its Authorized Registrar User’s
      information as available in the KYC records, including user signature(s).
      User agrees that such records may be used by the BSE/AMC/Authorised
      Registrar for authorizing the transactions that may be submitted by User
      in physical/offline mode.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      To contact User regarding matters pertaining to the operation of User
      account including, but not limited to, servicing of Users requests,
      communication of special offers, new product launches and product
      recommendations. This consent will override any registration for Do Not
      Call ("
    </span>
    <strong>DNC</strong>
    <span style={{ fontWeight: 400 }}>") / National Do Not Call ("</span>
    <strong>NDNC</strong>
    <span style={{ fontWeight: 400 }}>").</span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>Holding pattern and Customer details</strong>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      User acknowledges and agrees that the ArthGyan holding pattern of the
      Registered Customer, as reflected in the ArthGyan system, is deemed to be
      the ArthGyan account holding pattern of the account holder/investor(s).
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      User also agrees there will be no changes allowed in the holding pattern
      of the investors associated with the Account once the Account is
      activated.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      User agrees that once an investment is made using the holding pattern,
      User will not be allowed to change the holding pattern for the
      subscriptions.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      The other requisite data (which is not part of the form) required to be
      reported to BSE / authorized registrar of the AMC will be extracted from
      ArthGyan account details maintained for the First holder. The mode of
      holding in all customer joint accounts is treated as "
    </span>
    <strong>Anyone or Survivor</strong>
    <span style={{ fontWeight: 400 }}>".</span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>Account Statement</strong>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      User agrees and acknowledges that it shall be the obligation of the AMC
      and not of ArthGyan to regularly send to the Registered Customer such
      communications as required under the SEBI Regulations.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>Fees and Charges</strong>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      User acknowledges that ArthGyan reserves the right to charge fees for the
      Services or any other services offered by ArthGyan.{" "}
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      User agrees and understand that any such charges/fees that ArthGyan may
      choose to levy will be in addition to those levied by the mutual funds, if
      any. All fees, charges and reimbursement of expenditure shall be paid or
      made in full by user without any counter claim, set off or withholding.
      All liabilities and obligations of the Registered Customer(s) hereunder to
      ArthGyan/mutual fund/AMCs/trustees of the AMC shall be joint and/or
      several, as the case may be.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>LIABILITY OF ARTHGYAN</strong>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      ArthGyan shall not in absence of gross negligence on the part of ArthGyan,
      be liable to User for any act, omission or delay by the mutual fund or for
      any claims which User may suffer or incur as a result of or in course or
      discharge by ArthGyan or its employees, officers, directors, nominee or
      agent of ArthGyan’s duties.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      Without prejudice to the above, ArthGyan shall not be held liable for any
      loss or damage or failure to comply or delay in complying with its
      obligations under these Terms and Conditions which is caused directly or
      indirectly by any event or circumstances beyond ArthGyan’s reasonable
      control. These include system failure, network errors, delay or loss of
      data due to the above and in circumstances of acts of God, floods,
      epidemics, quarantine, riot, civil commotion and/or war. User further
      agrees that ArthGyan shall not be held liable for any losses, damages,
      expenses, costs, liabilities and claims of whatsoever nature caused by
      fraudulent or unauthorized use or access of User information, and/or
      signatures. ArthGyan will be under no duty to verify compliance with any
      restrictions on users investment powers.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      ArthGyan will not be liable for any loss, damage, cost, charges or
      expenses directly or indirectly caused by reasons of any defects or
      imperfections or mechanical or other failure with relation to computer,
      cable, telex, telephone or postal system.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      While ArthGyan will make every effort to have its computer systems
      available at all times, ArthGyan makes no guarantees with respect to the
      availability of such systems. ArthGyan will make every effort to resolve
      availability issues such as network problems, virus attacks etc. in an
      expeditious manner. Notwithstanding these, ArthGyan will as such not be
      liable for any loss, damage, cost, charges or expenses directly or
      indirectly caused by reasons of lack of such availability.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      ArthGyan shall not be liable for any loss or damage caused by reason of
      failures or delay of the mutual fund to deliver any units purchased even
      though payments have been made for the same, or failure or delay in making
      payment in respect of any units sold, though they may have been delivered
      and user shall hold ArthGyan harmless and free from any claim in respect
      thereof. ArthGyan shall also not be liable for any delay, failure or
      refusal of the mutual fund in registering or transferring units to user
      names or for any interest, dividend or other loss caused to user arising
      therefrom.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      In instances of third party claims, ArthGyan shall not be liable for any
      failure/delay, wherein such claims/losses are arising due to a reason
      entirely attributable to an error or gross negligence of the mutual
      funds/BSE/AMC.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>ONLINE PAYMENT – PAYMENT GATEWAY TERMS</strong>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      The funds transfer for purchase of units of mutual funds will be done by
      using an Electronic Payment Gateway facility, ONLY internet banking
      facility shall be offered for transaction on the Application, through a
      third party net banking service provider registered with the Reserve Bank
      of India. User cannot use or permit the use of the payment gateway or any
      related services for any illegal or improper purposes.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      Users shall utilize the Electronic Payment Gateway at their own risk.
      These risks would include but not be limited to the following risks and
      ArthGyan disclaims all liability and responsibility for any claims,
      losses, damages, costs of whatsoever nature arising due to such risks:
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>Misuse of Password:</strong>
    <span style={{ fontWeight: 400 }}>
      &nbsp;If any third party obtains access to user password of the
      Investments Services Account, such third party would be able to transact
      on ArthGyan’s online technology platform. User shall ensure that the terms
      and conditions applicable to the use of the Net Banking password as
      contained in the Electronic Payment Gateway for Net Banking are complied
      with at all times.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>Internet Frauds:</strong>
    <span style={{ fontWeight: 400 }}>
      &nbsp;The Internet per se is susceptible to a number of frauds, misuse,
      hacking and other actions, which could affect payment instructions given
      using Electronic Payment Gateway or the transactions done using ArthGyan’s
      online technology platform. Whilst ArthGyan shall aim to provide security
      to prevent the same, there cannot be any guarantee against such Internet
      frauds, hacking and other actions. User shall separately evolve/evaluate
      all risks arising out of the same.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>Mistakes and Errors:&nbsp;</strong>
    <span style={{ fontWeight: 400 }}>
      The filling in of applicable data for transfer would require proper,
      accurate and complete details. In the event of user account receiving an
      incorrect credit by reason of a mistake committed by any third party, the
      concerned mutual fund/AMC or the bank shall be entitled to reverse the
      incorrect credit at any time whatsoever without the consent of the User.
      User shall be liable and responsible to accept any such instructions
      received from the AMC, without questions for any unfair or unjust gain
      obtained by ArthGyan as a result of the same.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>Technology Risks:</strong>
    <span style={{ fontWeight: 400 }}>
      &nbsp;The technology for enabling the transfer of funds and the other
      services offered by the Electronic Payment and ArthGyan could be affected
      by virus or other malicious, destructive or corrupting code, program or
      macro. ArthGyan’s Application or the bank may require maintenance and
      during such time it may not be possible to process the user request. This
      could result in delays in the processing of transactions/payment
      instructions or failure in the processing of transactions/payment
      instructions and other such failures and inability. User understands that
      ArthGyan disclaims all and any liability, whether direct or indirect,
      whether arising out of loss of profit or otherwise arising out of any
      failure or inability by bank/mutual funds/AMCs to process any
      transaction/payment instructions for whatsoever reason. ArthGyan shall not
      be responsible for any of the aforesaid risks.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>Limits:</strong>
    <span style={{ fontWeight: 400 }}>
      &nbsp;ArthGyan may from time to time impose maximum and minimum limits on
      funds that may be transferred by virtue of the payment transfer service
      given. User realizes and accepts and agrees that the same is to reduce the
      risks of the user. User shall be bound by such limits imposed and shall
      strictly comply with them.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>Indemnity:</strong>
    <span style={{ fontWeight: 400 }}>
      &nbsp;User shall indemnify ArthGyan, its service partners and third party
      vendors&nbsp; from and against all losses and damages that may be caused
      as a consequence of breach of any of the Electronic Payment Gateway for
      net banking and the terms and conditions mentioned herein above.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>Authentication of the Transaction:&nbsp;</strong>
    <span style={{ fontWeight: 400 }}>
      User are authorized to make transaction only with and from the bank
      account(s) registered on the Application. Banks has all the right to
      cancel, reject and/or unauthorize the transaction made by the user, from
      any other account, details of which is not registered or provided to
      ArthGyan. Such payment which is rejected by the banks shall be notified or
      intimated to the user, in the morning of the next business day.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>Transaction Verification:</strong>
    <span style={{ fontWeight: 400 }}>
      &nbsp;The transaction made by the user, whether approved or rejected,
      using the ArthGyan shall be subject to the following level of
      verifications:
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>ArthGyan:</strong>
    <span style={{ fontWeight: 400 }}>
      &nbsp;Transaction will be rejected in case the bank account though which
      the payment is made, is not registered with ArthGyan;
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>BSE:&nbsp;</strong>
    <span style={{ fontWeight: 400 }}>
      Transaction made from the bank account which is different from the account
      details provided by ArthGyan to BSE.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      AMC: Final, rejection could be made by the AMC, upon receiving the
      transaction details.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>Withdrawal of Facility:</strong>
    <span style={{ fontWeight: 400 }}>
      &nbsp;ArthGyan shall be entitled to withdraw this service at any time
      without assigning any reason whatsoever.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      Binding nature of above terms and conditions: By use of this facility,
      user shall be deemed to have agreed to all the above terms and conditions
      and such terms and conditions shall bound the User in the same manner as
      if the user have agreed to the same in writing.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>FUNCTION OF ARTHGYAN &amp; DISCLAIMER</strong>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      Any information contained in ArthGyan brochures or other materials or
      otherwise communicated by ArthGyan shall not be construed as investment
      advice and that all decisions to purchase or sell units made by user shall
      be on the basis of personal judgment arrived at after due consideration.
      ArthGyan does not in any manner:
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      • &nbsp; &nbsp;Guarantee payments on any units; or
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      • &nbsp; &nbsp;Guarantee liquidity of any units; or
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      • &nbsp; &nbsp;Make any offer to buy back any units; or
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      • &nbsp; &nbsp;Guarantee the redemption or repayment of any units on
      maturity; or
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      • &nbsp; &nbsp;Guarantee the payments of interest or dividend; or
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      • &nbsp; &nbsp;Promise, indicate or guarantee any returns; or
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      • &nbsp; &nbsp;Guarantee any good delivery.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      • &nbsp; &nbsp;Subscribe to units of mutual funds on behalf or in name of
      User or collects payments from User for the units so purchased by User for
      remitting it further to the AMCs; or
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      • &nbsp; &nbsp;Receive any account statement from mutual funds/AMCs, on
      behalf of or in the Users name pertaining to the units; or
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      • &nbsp; &nbsp;Redeem /sell the units held by User or on its behalf or in
      its name; or unilaterally instruct the mutual fund and/or the
      corresponding AMCs with regards to nomination/changes in investments
      plan/any other changes; or
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      • &nbsp; &nbsp;Sign any document on behalf of or in the name of User for
      purchase, sale or redemption of units; or
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      • &nbsp; &nbsp;Collect, receive and / or give receipts and discharges for
      any sum including dividend, interest or income arising from the units and
      does not sign and/or endorse dividend and interest warrants on my/our
      behalf or in my/our name; or
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      • &nbsp; &nbsp;Correspond with or gives notice to the mutual fund/AMCs on
      behalf of or in the name of User, except for transmission of transactions
      done or purported to be done by User on the online technology platform.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      ArthGyan does not make any promises to the User basis the graphical
      representation provided on the Application. The data collected from the
      User to calculate the prospective investments amount in relation to the
      past investment history of the User shall neither be construed as an
      authoritative advice to the User nor be considered as an indication of
      future returns from the investments of the User. ArthGyan does not offer
      any advice and nothing herein or on the ArthGyan Application shall be
      construed as investment advice by User.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      Any sum invested through ArthGyan Investment Account is not a deposit with
      ArthGyan and is not bank insured. The same is not endorsed or guaranteed
      and does not constitute obligations of ArthGyan or any of the subsidiaries
      associates or affiliates companies whose role in only as described in
      these Terms and Conditions. Investments in mutual fund are subject to
      investment risk, including the possible loss of principal amount invested.
      The value of the units purchased or not purchased will fluctuate. If User
      redeems the units/shares purchased, User may receive more or less than
      User has/had paid depending upon NAV of the units in the fund or trust at
      the time of redemption. Past results are not a guarantee to future
      performance. Past performance may or may not be sustained in the future.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>NOTICES/CORRESPONDENCE</strong>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      Any notice or other correspondence addressed by ArthGyan to User may be
      addressed to the address provided by the Users at the time of enrollment.
      If according to User there is any discrepancy in the particular or details
      of transaction or account of statement then, it shall be the
      responsibility of the User to intimate the same to ArthGyan and to the
      concerned mutual fund/AMC/authorised registrar of the AMC in writing
      within 7 (seven) working days of receiving notice thereof, falling which
      such transaction, statement or account (as the case may be) shall be
      deemed to be correct and accepted by User and Users shall not be entitled
      to question the correctness or accuracy thereof at any point in the
      future.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>USER INFORMATION</strong>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      User shall provide any and all information that may be required by
      ArthGyan/AMC/authorised service providers of the AMC from time to time in
      compliance with existing legal norms and regulations, including but not
      limited to contact information, address and tax related information and
      any information required under existing or future KYC norms and laws. User
      agrees that ArthGyan/AMC/authorized service providers of the AMC may take
      steps to verify the veracity of such information provided and shall
      co-operate with ArthGyan/AMC/authorized service providers of AMC in
      providing all information requested by ArthGyan for the purpose of
      rendering the services availed by the User. User acknowledges that failure
      to provide such information in a timely manner may result in ineligibility
      to avail of some or all of the services of ArthGyan and that ArthGyan
      shall in no way be held liable for any losses of whatsoever nature
      resulting from such failure to provide information on part of the User.
      Any information provided by the User may be required to be shared with
      relevant regulatory/statutory authorities and User authorises ArthGyan to
      provide all information to such regulatory / statutory authorities as and
      when required.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      The information shall be provided by the User and shall be governed in
      accordance with details provided in the privacy policy.{" "}
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>TERMINATION OF SERVICES</strong>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      ArthGyan may terminate the Services by giving 90 (ninety) days prior
      notice in writing to the Registered Customer provided that the Registered
      Customer shall not be relived of obligations hereunder notwithstanding
      such termination incurred prior to the date on which such termination
      shall become effective.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>REGULATORY CAUTION</strong>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      Mutual Fund investments are subject to market risks, read all
      scheme-related documents carefully before investing.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>GOVERNING LAW AND DISPUTE RESOLUTION</strong>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      All disputes and differences arising out of, under or in connection with
      these terms and conditions or anything done hereunder shall be within the
      exclusive jurisdiction only to the courts of Hyderabad. These terms and
      conditions are subject to and shall be construed in accordance with the
      laws prevalent in India.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      In the event of dispute between the User and ArthGyan on accuracy of
      transaction details provided by the User on the ArthGyan Application, the
      transaction logs maintained by ArthGyan back office will be the only
      source of data to verify the accuracy of such transactions.
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <strong>BROKERAGE</strong>
    <span style={{ fontWeight: 400 }}>
      <br />
    </span>
    <span style={{ fontWeight: 400 }}>
      For all investments made using ArthGyan, we charge the brokerages to the
      Asset Management Companies. The recommendations are not made on the basis
      of brokerage, and performance / fund details of all the mutual funds from
      competing AMCs are also provided to the User for reference.
    </span>
  </p>
  <p>&nbsp;</p>


    </Container>
    </>
  );
};

export default TermsAndConditions;

