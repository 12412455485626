import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Button,
  Grid2,
  Card,
  CardContent,
  CardMedia,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Footer from "./Footer";
import Navbar from "./Navbar"; // Import the Navbar component

// ... existing imports ...

const StyledHero = styled(Box)(({ theme }) => ({
  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://t4.ftcdn.net/jpg/02/81/89/73/360_F_281897358_3rj9ZBSZHo5s0L1ug7uuIHadSxh9Cc75.jpg')`,
  backgroundSize: "cover", // Ensures the image covers the area without stretching
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(8, 0, 6),
  textAlign: "center",
  height: "300px", // Adjust height as needed
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: 320,
  width: 240,
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  position: "relative",
  zIndex: 1,
  borderRadius: "16px",
  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-10px)",
    boxShadow: "0 12px 20px rgba(0,0,0,0.2)",
    zIndex: 2,
  },
}));

function HomePage() {
  const navigate = useNavigate();

  const handleApplyNow = () => {
    navigate("/banking-products");
  };
  
  const commingSoon = () => {
    navigate("/coming-soon");
  };

  return (
    <>
      <Navbar /> {/* Add the Navbar component here */}
      <StyledHero>
        <Container maxWidth="xl">
          <Typography
            variant="h2"
            component="h1"
            gutterBottom
            sx={{
              fontFamily: '"Nato Sherif", serif',
              fontWeight: "bold",
              marginBottom: "1rem",
              fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
            }}
          >
            ArthGyan
          </Typography>
          <Typography
            variant="h5"
            paragraph
            sx={{
              marginBottom: "3rem",
              fontSize: {
                xs: "1rem",
                sm: "1.25rem",
                md: "1.5rem",
              },
            }}
          >
            Personal Finance Manager Of Government Employees
          </Typography>
        </Container>
      </StyledHero>
      <Box
        sx={{
          position: "relative",
          marginTop: { xs: "-50px", sm: "-50px", md: "-60px" },
          zIndex: 2,
          paddingX: { xs: 1, sm: 2 },
        }}
      >
        <Card
          sx={{
            maxWidth: "95%",
            margin: "0 auto",
            borderRadius: "20px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
            overflow: "visible",
            background: "linear-gradient(180deg, #ffffff 0%, #f0f7ff 100%)",
          }}
        >
          <CardContent
            sx={{
              padding: { xs: "1rem", sm: "1.5rem", md: "2rem" },
            }}
          >
            <Typography
              variant="h3"
              component="h2"
              gutterBottom
              align="center"
              sx={{
                fontFamily: '"Nato Sherif", sans-serif',
                fontWeight: "bold",
                marginBottom: "2rem",
                color: "rgba(0, 48, 73, 1)",
                fontSize: {
                  xs: "1.75rem",
                  sm: "2.25rem",
                  md: "3rem",
                }, // Responsive font size
              }}
            >
              Our Services
            </Typography>
            <Grid2
  container
  spacing={{ xs: 2, sm: 2, md: 3 }}
  justifyContent="center"
>
  {[
{
  title: "Banking Products",
  image:
    "https://ik.imagekit.io/bankio/OnlineBanking.jpg?updatedAt=1729837921062",
  description: "Simple secure innovative banking products<br /><span style='color: #4682B4; font-weight: bold; display: inline-block; margin-top: 16px;'>EXPLORE OUR PRODUCT</span>",
  onClick: handleApplyNow,
},


  
    {
      title: "Insurance Services",
      image:
        "https://thumbs.dreamstime.com/b/life-insurance-concept-home-crystal-ball-37743040.jpg",
      description:
        "Bespoke insurance products with trusted partners.",
    },
    {
      title: "Investment Services",
      image:
        "https://ik.imagekit.io/bankio/InvestmentServices.jpeg?updatedAt=1729837920552",
      description:
        "Diverse portfolio<br />options for your investment goals.",
    },
    {
      title: "Service Benefits",
      image:
        "https://ik.imagekit.io/9jwvm0nw6/benefits1.jpeg?updatedAt=1733226348666",
      description:
        "All your government job related benefits at one place.",
    },
  ].map((feature, index) => (
    <Grid2
      item
      key={index}
      xs={12}
      sm={6}
      md={3}
      lg={3}
      sx={{ display: "flex" }}
    >
      <StyledCard
        onClick={feature.onClick || undefined}
        sx={{
          cursor: feature.onClick ? "pointer" : "default",
        }}
      >
        <CardMedia
          component="img"
          height="140"
          image={feature.image}
          alt={feature.title}
          sx={{
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
        <CardContent
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            component="h3"
            sx={{
              wordBreak: "break-word",
              fontFamily: '"Nato Sherif", sans-serif',
              fontWeight: "bold",
              marginBottom: 2,
              marginTop: 2,
            }}
          >
            {feature.title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            dangerouslySetInnerHTML={{
              __html: feature.description,
            }}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              wordBreak: "break-word",
            }}
          />
        </CardContent>
      </StyledCard>
    </Grid2>
  ))}
</Grid2>

          </CardContent>
        </Card>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 4,
          padding: 4,
          maxWidth: "950px",
          margin: "0 auto",
          mb: 4,
        }}
      >
        {/* Left box for image */}
        <Box
          sx={{
            flex: 1,
            backgroundColor: "white",
            borderRadius: "10px",
            overflow: "hidden",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
            height: { xs: "200px", md: "250px" },
          }}
        >
          <Box
            component="img"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            alt="Innovative financial Solutions"
            src="https://ik.imagekit.io/bankio/bankingSolutions.webp?updatedAt=1731908326360"
          />
        </Box>

        {/* Right box for text content */}
        <Box
          sx={{
            flex: 1,
            backgroundColor: "white",
            padding: 3,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h3"
            component="h2"
            gutterBottom
            sx={{
              fontFamily: '"Nato Sherif", sans-serif',
              fontWeight: "bold",
              color: "rgba(0, 48, 73, 1)",
              fontSize: {
                xs: "1.75rem",
                sm: "2rem",
                md: "2.5rem",
              },
            }}
          >
            Banking Solutions
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{
              textAlign: "left",
              fontSize: { xs: "0.875rem", sm: "1rem" },
            }}
          >
            We're committed to providing cutting-edge financial solutions that
            meet the evolving needs of our customers.
          </Typography>

          <Button
            variant="text"
            size="large"
            endIcon={<ArrowForwardIcon />}
            sx={{
              backgroundColor: "transparent",
              borderRadius: "50px",
              padding: "10px 20px",
              color: "rgba(0, 48, 73, 1)",
              fontWeight: "bold",
              fontFamily: '"Nato Sherif", sans-serif',
              "&:hover": {
                backgroundColor: "rgba(0, 48, 73, 1)",
                color: "#ffffff",
                borderRadius: "50px",
              },
            }}
            onClick={handleApplyNow}
          >
            Explore Our products
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 4,
          padding: 4,
          maxWidth: "1000px",
          margin: "0 auto",
          mb: 4,
        }}
      >
        {/* Left box for text content */}
        <Box
          sx={{
            flex: 1,
            backgroundColor: "white",
            padding: 3,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h3"
            component="h2"
            gutterBottom
            sx={{
              fontFamily: '"Nato Sherif", sans-serif',
              fontWeight: "bold",
              color: "rgba(0, 48, 73, 1)",
              fontSize: {
                xs: "1.75rem",
                sm: "2rem",
                md: "2.5rem",
              },
            }}
          >
            Insurance
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{
              textAlign: "left",
              fontSize: { xs: "0.875rem", sm: "1rem" },
            }}
          >
            We understand the importance of financial security and growth. Our
            state-of-the-art security measures and innovative financial products
            are designed to protect your assets and help them grow.
          </Typography>

          <Button
            variant="text"
            size="large"
            endIcon={<ArrowForwardIcon />}
            sx={{
              backgroundColor: "transparent",
              borderRadius: "50px",
              padding: "10px 20px",

              color: "rgba(0, 48, 73, 1)",
              fontWeight: "bold",
              fontFamily: '"Nato Sherif", sans-serif',
              "&:hover": {
                backgroundColor: "rgba(0, 48, 73, 1)",
                color: "#ffffff",
                borderRadius: "50px",
              },
            }}
            onClick={commingSoon}
          >
            Coming soon
          </Button>
        </Box>

        {/* Right box for image */}
        <Box
          sx={{
            flex: 1,
            backgroundColor: "white",
            borderRadius: "10px",
            overflow: "hidden",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
            height: { xs: "200px", md: "250px" },
          }}
        >
          <Box
            component="img"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            alt="Secure Banking"
            src="https://ik.imagekit.io/bankio/lifeInsurance.jpg?updatedAt=1731908326339"
          />
        </Box>
      </Box>
      {/* New section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 4,
          padding: 4,
          maxWidth: "1000px",
          margin: "0 auto",
          mb: 4,
        }}
      >
        {/* Left box for text content */}

        {/* Right box for image */}
        <Box
          sx={{
            flex: 1,
            backgroundColor: "white",
            borderRadius: "10px",
            overflow: "hidden",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
            height: { xs: "200px", md: "250px" },
          }}
        >
          <Box
            component="img"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            alt="Secure Banking"
            src="https://ik.imagekit.io/9jwvm0nw6/investment.avif?updatedAt=1733225653522"
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            backgroundColor: "white",
            padding: 3,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h3"
            component="h2"
            gutterBottom
            sx={{
              fontFamily: '"Nato Sherif", sans-serif',
              fontWeight: "bold",
              color: "rgba(0, 48, 73, 1)",
              fontSize: {
                xs: "1.75rem",
                sm: "2rem",
                md: "2.5rem",
              },
            }}
          >
            Investment Services
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{
              textAlign: "left",
              fontSize: { xs: "0.875rem", sm: "1rem" },
            }}
          >
            We offer customized investment plans designed to align with your
            financial goals, whether you're saving for the future or building
            wealth.
          </Typography>

          <Button
            variant="text"
            size="large"
            endIcon={<ArrowForwardIcon />}
            sx={{
              backgroundColor: "transparent",
              borderRadius: "50px",
              padding: "10px 20px",

              color: "rgba(0, 48, 73, 1)",
              fontWeight: "bold",
              fontFamily: '"Nato Sherif", sans-serif',
              "&:hover": {
                backgroundColor: "rgba(0, 48, 73, 1)",
                color: "#ffffff",
                borderRadius: "50px",
              },
            }}
            onClick={commingSoon}
          >
            Coming soon
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 4,
          padding: 4,
          maxWidth: "1000px",
          margin: "0 auto",
          mb: 4,
        }}
      >
        {/* Left box for text content */}
        <Box
          sx={{
            flex: 1,
            backgroundColor: "white",
            padding: 3,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h3"
            component="h2"
            gutterBottom
            sx={{
              fontFamily: '"Nato Sherif", sans-serif',
              fontWeight: "bold",
              color: "rgba(0, 48, 73, 1)",
              fontSize: {
                xs: "1.75rem",
                sm: "2rem",
                md: "2.5rem",
              },
            }}
          >
            Service Benefits
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{
              textAlign: "left",
              fontSize: { xs: "0.875rem", sm: "1rem" },
            }}
          >
            Discover exclusive benefits and special perks designed specifically
            for government employees. Our platform helps you navigate and
            maximize your entitled benefits, ensuring you make the most of your
            service privileges.
          </Typography>

          <Button
            variant="text"
            size="large"
            endIcon={<ArrowForwardIcon />}
            sx={{
              backgroundColor: "transparent",
              borderRadius: "50px",
              padding: "10px 20px",
              color: "rgba(0, 48, 73, 1)",
              fontWeight: "bold",
              fontFamily: '"Nato Sherif", sans-serif',
              "&:hover": {
                backgroundColor: "rgba(0, 48, 73, 1)",
                color: "#ffffff",
                borderRadius: "50px",
              },
            }}
            onClick={commingSoon}
          >
            Coming soon
          </Button>
        </Box>

        {/* Right box for image */}
        <Box
          sx={{
            flex: 1,
            backgroundColor: "white",
            borderRadius: "10px",
            overflow: "hidden",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
            height: { xs: "200px", md: "250px" },
          }}
        >
          <Box
            component="img"
            sx={{
              width: "100%",
              height: "100%",
            }}
            alt="Services Benefits"
            src="https://ik.imagekit.io/9jwvm0nw6/srvc.jpg?updatedAt=1733241599041"
          />
        </Box>
      </Box>
      <Footer />
    </>
  );
}

export default HomePage;
