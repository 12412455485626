import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Select,
  MenuItem,
  ButtonBase,
  CircularProgress,
  TextField,
  Button,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import creditCards from "../JSON/creditCardData.json"; // JSON file
import Navbar from "./Navbar";
import Footer from "./Footer";


const BankingProductsScreen = () => {
  const navigate = useNavigate();

  // ------------------------
  // Filtering & Toggle States
  // ------------------------
  const [filter, setFilter] = useState("recommended");
  const [bankFilter, setBankFilter] = useState("");

  const handleFilterChange = (event, newFilter) => {
    if (newFilter !== null) setFilter(newFilter);
  };

  const handleBankFilterChange = (event) => {
    setBankFilter(event.target.value);
  };

  // ------------------------
  // Pincode Check States
  // ------------------------
  const [pincode, setPincode] = useState("");
  const [pincodeResult, setPincodeResult] = useState(null);
  const [pincodeLoading, setPincodeLoading] = useState(false);
  const [pincodeError, setPincodeError] = useState(null);

  // ------------------------
  // Infinite Scroll for JSON Cards
  // ------------------------
  const [visibleCards, setVisibleCards] = useState(6);
  const [isLoading, setIsLoading] = useState(false);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  
  // Navigate to card details page
  const handleCardClick = (id) => {
    navigate(`/credit-cards/${id}`);
  };

  // Filter JSON cards based on toggle and bank dropdown
  const filteredCards = creditCards
    .filter((card) => {
      if (filter === "recommended") return card.recommended === true;
      if (filter === "free") return card.type === "free";
      if (filter === "secured") return card.type === "secured";
      if (filter === "fuel") return card.type === "fuel";
      return true;
    })
    .filter((card) => {
      if (bankFilter) {
        const bankName = card.hero.title.split(" ")[0].toLowerCase();
        return bankName === bankFilter.toLowerCase();
      }
      return true;
    });

  // Infinite scroll logic (only for JSON cards when no pincode result exists)
  const loadMoreCards = useCallback(() => {
    if (filteredCards.length > visibleCards) {
      setIsLoading(true);
      setTimeout(() => {
        setVisibleCards((prev) => prev + 4);
        setIsLoading(false);
      }, 1000);
    }
  }, [filteredCards.length, visibleCards]);

  const handleScroll = useCallback(() => {
    if (!pincodeResult) {
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollTop = document.documentElement.scrollTop;
      const clientHeight = window.innerHeight;
      if (scrollTop + clientHeight >= scrollHeight - 500 && !isLoading) {
        loadMoreCards();
      }
    }
  }, [pincodeResult, isLoading, loadMoreCards]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  // ------------------------
  // Pincode Check Function
  // ------------------------
  const handleCheckPincode = async () => {
    if (!pincode.trim()) return;
    setPincodeLoading(true);
    setPincodeError(null);
    setPincodeResult(null);
    try {
      const response = await fetch(`${BACKEND_URL}/check?pincode=${pincode}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setPincodeResult(data);
    } catch (err) {
      setPincodeError("Error checking pincode. Please try again.");
    } finally {
      setPincodeLoading(false);
    }
  };

  const handleClearPincode = () => {
    setPincode("");
    setPincodeResult(null);
    setPincodeError(null);
  };

  return (
    <Box>
      <Navbar />
      {/* Hero Section */}
      <Box
        sx={{
          width: "100%",
          height: "300px",
          backgroundImage:
            "linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('https://t4.ftcdn.net/jpg/02/81/89/73/360_F_281897358_3rj9ZBSZHo5s0L1ug7uuIHadSxh9Cc75.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          color: "white",
        }}
      >
        <Typography
          variant="h2"
          component="h1"
          gutterBottom
          sx={{
            fontFamily: '"Nato Sherif", serif',
            fontWeight: "bold",
            fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
          }}
        >
          Banking Products
        </Typography>
      </Box>

      {/* Pincode Check Section */}
      <Box
        sx={{
          margin: 3,
          padding: 2,
          border: "1px solid #ccc",
          borderRadius: "8px",
          backgroundColor: "#fff",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Check Available Credit Cards by Pincode
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, marginBottom: 2 }}>
          <TextField
            label="Enter Pincode"
            variant="outlined"
            value={pincode}
            onChange={(e) => setPincode(e.target.value)}
            size="small"
          />
          <Button variant="contained" onClick={handleCheckPincode}>
            Check
          </Button>
          {pincodeResult && (
            <Button variant="outlined" color="secondary" onClick={handleClearPincode}>
              Clear Pincode
            </Button>
          )}
        </Box>
        {pincodeLoading && <CircularProgress size={24} />}
        {pincodeError && (
          <Typography color="error" variant="body2">
            {pincodeError}
          </Typography>
        )}
        {pincodeResult && (
          <Box sx={{ marginTop: 2 }}>
            <Typography variant="subtitle1">Pincode: {pincodeResult.pincode}</Typography>
            <Typography variant="subtitle1">
              Available Banks: {pincodeResult.availableBanks.join(", ")}
            </Typography>
          </Box>
        )}
      </Box>

      {/* Show Filtering Options & JSON Cards only if no pincode result */}
      {!pincodeResult && (
        <Box
          sx={{
            width: "90%",
            marginX: "auto",
            padding: 3,
            backgroundColor: "#f5f5f5",
            borderRadius: "16px",
            marginBottom: "1rem",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          }}
        >
          {/* Filtering Toggle Button & Bank Filter */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1.5rem",
              flexWrap: "wrap",
              gap: "2rem",
            }}
          >
            <ToggleButtonGroup
              value={filter}
              exclusive
              onChange={handleFilterChange}
              aria-label="filter"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                gap: "8px",
                "& .MuiToggleButton-root": {
                  width: "auto",
                  height: "40px",
                  fontSize: { xs: "0.75rem", sm: "0.875rem" },
                  padding: { xs: "0 8px", sm: "0 12px" },
                  border: "1px solid #d0d0d0",
                  borderRadius: "4px",
                  backgroundColor: "#ffffff",
                  color: "#000000",
                  "&.Mui-selected": {
                    backgroundColor: "#81BFDA",
                    color: "#000000",
                    fontWeight: "bold",
                    borderColor: "#81BFDA",
                  },
                  "&:hover": {
                    backgroundColor: "#f0f0f0",
                    borderColor: "#b0b0b0",
                    color: "#000000",
                  },
                },
              }}
            >
              <ToggleButton value="recommended" aria-label="recommended">
                Recommended
              </ToggleButton>
              <ToggleButton value="free" aria-label="free">
                Life Time Free
              </ToggleButton>
              <ToggleButton value="secured" aria-label="secured">
                Secured Card
              </ToggleButton>
              <ToggleButton value="fuel" aria-label="fuel">
                Fuel
              </ToggleButton>
            </ToggleButtonGroup>
            <Select
              value={bankFilter}
              onChange={handleBankFilterChange}
              displayEmpty
              variant="outlined"
              sx={{
                width: { xs: "100%", sm: "200px" },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  height: "50px",
                  fontSize: "0.75rem",
                  padding: "5px px",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: 6,
                  },
                },
                "& .MuiSelect-select": {
                  padding: "4px 8px",
                },
              }}
            >
              <MenuItem
                value=""
                sx={{
                  fontSize: "0.75rem",
                  "&:hover": {
                    backgroundColor: "#81BFDA",
                    color: "#000000",
                  },
                }}
              >
                All Banks
              </MenuItem>
              {["SBI", "HDFC", "Axis", "IndusInd", "IDFC", "HSBC", "RBL"].map((bank) => (
                <MenuItem
                  key={bank}
                  value={bank}
                  sx={{
                    fontSize: "0.75rem",
                    "&:hover": {
                      backgroundColor: "#81BFDA",
                      color: "#ffffff",
                    },
                  }}
                >
                  {bank}
                </MenuItem>
              ))}
            </Select>
          </Box>

          {/* Card Grid for JSON Cards */}
          <Grid container spacing={3}>
            {filteredCards.slice(0, visibleCards).map((card) => (
              <Grid item xs={12} sm={6} md={3} key={card.id}>
                <ButtonBase
                  onClick={() => handleCardClick(card.id)}
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Card
                    sx={{
                      transition: "transform 0.2s, box-shadow 0.2s",
                      borderRadius: "16px",
                      boxShadow: 3,
                      backgroundColor: "#ffffff",
                      display: "flex",
                      flexDirection: "column",
                      height: "350px",
                      "&:hover": { transform: "scale(1.05)", boxShadow: 6 },
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="200"
                      image={card.card.image}
                      alt={card.hero.title}
                      loading="lazy"
                      sx={{
                        borderTopLeftRadius: "16px",
                        borderTopRightRadius: "16px",
                        objectFit: "contain",
                        width: "100%",
                      }}
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography variant="h6" sx={{ fontWeight: "800" }} gutterBottom>
                        {card.hero.title}
                      </Typography>
                    </CardContent>
                    <Box
                      sx={{
                        backgroundColor: "#1687c9",
                        borderBottomLeftRadius: "16px",
                        borderBottomRightRadius: "16px",
                        padding: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "bold", color: "white", textAlign: "center" }}>
                        Benefits & Eligibility
                      </Typography>
                    </Box>
                  </Card>
                </ButtonBase>
              </Grid>
            ))}
          </Grid>
          {isLoading && filteredCards.length > visibleCards && (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 3 }}>
              <CircularProgress color="primary" />
            </Box>
          )}
        </Box>
      )}

      {/* If pincodeResult exists, show API response cards */}
      {pincodeResult && (
        <Box
          sx={{
            width: "90%",
            marginX: "auto",
            padding: 3,
            backgroundColor: "#f5f5f5",
            borderRadius: "16px",
            marginBottom: "2rem",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          }}
        >
          <Grid container spacing={3}>
            {pincodeResult.availableCards.map((card) => (
              <Grid item xs={12} sm={6} md={3} key={card.id}>
                <ButtonBase
                  onClick={() => handleCardClick(card.id)}
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Card
                    sx={{
                      transition: "transform 0.2s, box-shadow 0.2s",
                      borderRadius: "16px",
                      boxShadow: 3,
                      backgroundColor: "#ffffff",
                      display: "flex",
                      flexDirection: "column",
                      height: "350px",
                      "&:hover": { transform: "scale(1.05)", boxShadow: 6 },
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="200"
                      image={card.card.image}
                      alt={card.hero.title}
                      loading="lazy"
                      sx={{
                        borderTopLeftRadius: "16px",
                        borderTopRightRadius: "16px",
                        objectFit: "contain",
                        width: "100%",
                      }}
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography variant="h6" sx={{ fontWeight: "800" }} gutterBottom>
                        {card.hero.title}
                      </Typography>
                    </CardContent>
                    <Box
                      sx={{
                        backgroundColor: "#1687c9",
                        borderBottomLeftRadius: "16px",
                        borderBottomRightRadius: "16px",
                        padding: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "bold", color: "white", textAlign: "center" }}>
                        Benefits & Eligibility
                      </Typography>
                    </Box>
                  </Card>
                </ButtonBase>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      <Footer />
    </Box>
  );
};

export default BankingProductsScreen;
