import React from "react";
import {
  Box,
  Typography,
  Container,
  Paper,
  Divider,
  CssBaseline,
} from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <>
      <CssBaseline />
      <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
        <Paper elevation={3} sx={{ p: 4 }}>
          <Typography variant="h4" gutterBottom sx={{ fontSize: "1.5rem" }}>
            Privacy Policy - Arthgyan.com
          </Typography>

          <Divider sx={{ my: 2 }} />

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              Privacy Policy:
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Date of last revision: January 22, 2025
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              By signing up for the Services, or providing us with your personal information for any other purpose, You agree to the terms of this Privacy Policy.
              This policy will help you understand the types of information we collect from You, the purpose for which the information is collected; how the information is used; the intended recipients of such information; and how the information is protected.
              For the purposes of this policy, "personal information" means any information that can be used to identify you personally.
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              HOW WE COLLECT AND USE YOUR PERSONAL INFORMATION
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Pre-registration - You cannot access any section or features of ArthGyan without registering or disclosing any personal information. As and when you download and install the app, remember that we capture anonymous visitor data such as pattern of your usage of the app. We use this data together with similar data from other visitors to understand and control the usage of the app only in certain areas where our services are currently available.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Registration - To access the ArthGyan application, you will be asked to register for a registration account. This involves creating a Username and Password (collectively, “Login Credentials”).
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Investment - To avail the functionality of our investment services and start investing, you will be required to provide us with certain additional information which may be personal or non-personal such as:
            </Typography>
            <ul>
              <li>Contact Details such as name, father's name, age, e-mail address etc.</li>
              <li>Demographic Data such as your mailing/communication postal address, pin code etc.</li>
              <li>Bank account details with which you will be investing</li>
              <li>Any other documents required under KYC norms under applicable laws of India.</li>
            </ul>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              (collectively, your “Registration Information”).
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mt={2}>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Further, you will be required to share your correct banking details with us as any money withdrawn by you shall directly get deposited to your concerned bank account, as provided by you. We may also ask for additional personal information from time to time to meet new or any amended regulatory requirements or to provide you with other benefits of our Services as mutually agreed.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              ArthGyan shall ensure that your personal information or data shall be collected only with your prior valid consent, and that it shall be used or processed only in accordance with the applicable laws including but not limited to Information Technology Act, 2000, the Digital Personal Data Protection Act 2023 when applicable, and the corresponding Rules framed thereunder, more specifically the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 and the Privacy Policy contained herein.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              It is further confirmed by ArthGyan that all personal information/ data collected is/shall be used for intended lawful purposes including but not limited to internal monitoring and compliance, administrative processes and/ or statutory compliance with applicable laws and the same are in accordance with the provisions of Information Technology Act, 2000 and the Digital Personal Data Protection Act, 2023 when applicable, and its corresponding Rules and Regulations made thereunder. ArthGyan will use personal information to better serve you and enhance your experience with the Services. We hereby set out a list of purposes for which we use your personal/ non-personal information and the same is subject to changes/ amendments as per the requirements of applicable law from time to time:
            </Typography>
            <ul>
              <li>To administer your account;</li>
              <li>To send you investment related information.</li>
              <li>To provide you monthly investment statement.</li>
              <li>To conduct research and analysis.</li>
              <li>To respond to customer service inquiries.</li>
              <li>To send marketing materials, if opted.</li>
              <li>To improve our access to the App.</li>
              <li>To meet any regulatory requirements including KYC Norms.</li>
              <li>To facilitate the processing of data between you and a third party.</li>
            </ul>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              SHARING YOUR PERSONAL INFORMATION
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Under no circumstance will we sell or rent your personal information to anyone, for any reason, at any time. Access to your Login Credentials, Registration Information, and any other personal information you provide is restricted and used in accordance with specific internal procedures in order to operate, develop or improve our services and the same shall always be in accordance with the provisions of the applicable laws.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              We may also use third party vendors, professionals such as advisors or service providers to help us provide services to you, such as maintaining the track record of your investment or couriering documents to you on our behalf or operating the payment gateway functionality of the website. Such third parties are required to maintain the confidentiality of the information we provide to them under contract and under law. We ensure to avail necessary safeguard measures while transferring your personal information to such third-parties as prescribed under the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 and other applicable laws from time to time.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
            We reserve the right to disclose your personal information in the scope and in situations as required by the law and when we believe in good faith that such disclosure is necessary to protect our rights, to report suspected illegal activity, comply with judicial proceedings, court orders/ statutory or regulatory orders or decisions, or legal process served on us/ our website
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
            We may also use your information, and/ or permit selected third parties including service providers, on the Website, with your prior consent, to use your information or provide you with such information which may be of interest to you.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
            We cannot guarantee the security of your data while it is being transmitted over the Internet and through servers that are out of our control. We do our best to protect your personal information but we cannot ensure or warrant the security of any information you transmit to our Website or Services. Therefore, if you make any data transmissions over the Internet, you are doing it at your own risk. Once we receive the data transmission, we make our best efforts to ensure its security and privacy on our systems. Nevertheless, ArthGyan assures you that it will provide necessary assistance and support to you to report to the relevant authorities regarding any breach or unauthorized access to your personal information/ data, as required under applicable laws. 
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}></Typography>

            
          </Box>

          <Divider sx={{ my: 2 }} />

          
          <Box mt={2}>
          <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
          THIRD PARTY WEBSITES
            </Typography>
          <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
          Our Website may, from time to time, contain links to and from the websites of Our partner networks, affiliates and other third parties. This Privacy Policy does not cover the collection of information by cookies or other methods by third parties. We do not control how such third parties collect information or by what means as such third parties may use their own cookies to collect information about you. Please remember that after we disclose any personal information to third parties, we can no longer control the use of such personal information, though we ensure that such third parties are subject to confidentiality obligations. Accordingly, ArthGyan will not be liable for the acts or omissions of any third party. Please check the privacy policies and the terms of the third-party websites before you submit any information to such websites.

          </Typography>
          </Box>
          <Box mt={2}>
          <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
          INFORMATION STORAGE AND SECURITY
            </Typography>
            </Box>
           
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
            We have implemented a wide range of measures in order to ensure the security and confidentiality of your data. These include physical, electronic and administrative safeguards such as firewalls, data encryption, SSL and other up-to-date technologies.
            </Typography>
            <Box mt={2}>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
            No security measures, however, are 100% complete. Therefore, we do not promise and cannot guarantee, and thus you should not expect, that your personal information or private communications will not be collected and used by others. You should take steps to protect against unauthorized access to your password, phone, and computer by, among other things, signing off after using a shared computer, choosing a robust password that nobody else knows or can easily guess, and keeping your log-in and password private. We are not responsible for the unauthorized use of your information or for any lost, stolen, compromised passwords, or for any activity on your Account via unauthorized password activity.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
            We cannot guarantee the security of your data while it is being transmitted over the Internet and through servers that are out of our control. We do our best to protect your personal information but we cannot ensure or warrant the security of any information you transmit to our Website or Services. Therefore, if you make any data transmissions over the Internet, you are doing it at your own risk. Once we receive the data transmission, we make our best efforts to ensure its security and privacy on our systems. Nevertheless, ArthGyan assures you that it will provide necessary assistance and support to you to report to the relevant authorities regarding any breach or unauthorized access to your personal information/ data, as required under applicable laws
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
            If you have any questions about the accuracy or safety of your information, please do contact us at the address given below:
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
            Email: pthakur@arthgyan.com
            </Typography>
            </Box>
            <Divider sx={{ my: 2 }} />

            <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
            HOW YOU CAN ACCESS AND UPDATE YOUR PERSONAL INFORMATION
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
            Your personal information is yours. As a Data Principal, you have exclusive access and control over your personal information/ data and your rights concerning such personal information/ data shall include:

            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
            <ul>
              <li>	Confirmation and access to personal information/ data,;</li>
              <li>	Correction of inaccuracies of your personal information/ data,.</li>
              <li>	Objection or restriction on the processing or disclosure of your personal information/ data,</li>
              <li>	Portability of your personal information/ data,</li>
              <li>	Be informed of processing activities of your personal information/ data,</li>
              <li>	Erasure of your personal information/ data and,</li>
              <li>	Withdrawal of your consent to further processing and erasure of your retained personal information/ data by ArthGyan.</li>
             
            </ul>
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
 
            These rights are available to you irrespective of the time period during which your personal information/ data is under our possession and you are entitled to enforce your aforementioned rights in the manner set out in the Digital Personal Data Protection Act, 2023.

            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
            You have the right to withdraw or revoke your consent regarding collection, storage or processing of personal information/ data at any time in the manner specified under the Digital Personal Data Protection Act, 2023, and such revocation will not result in penalty or loss of any benefits to which you are entitled otherwise.
 

            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
            Please understand that you are solely responsible for consequences of the withdrawal of your consent as referred to in the above paragraph, and such withdrawal of consent shall not affect the legality of processing of your personal information/ data by ArthGyan based on your consent before its withdrawal
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
            To access or update your personal information, please login and make changes as required. Some of these changes may require you to provide documentary evidence. As an added security measure, we may require additional proof of identity prior to such disclosure. Remember to keep your Login Credentials safe and secret to prevent unauthorized access to your ArthGyan account. If you think that the security of your account has been compromised, change your password and contact our Data Protection Officer immediately for further assistance.
            </Typography>
            </Box>
            <Divider sx={{ my: 2 }} />

            <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
 
            OUR LEGAL OBLIGATION TO YOU

           </Typography>
           <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
           We are bound by the (Indian) Information Technology Act, 2000 and the Digital Personal Data Protection Act, 2023 and is compliant with all its provisions. Under Regulation 4 of the Information Technology (Reasonable Security and Procedures and Sensitive Personal Data or Information) Rules, 2011, ArthGyan  is obliged to provide every registered user of ArthGyan with a readable copy of the information that it keeps about you. If you would like a copy, do get in touch with the contact details provided above.
 

           </Typography>
  </Box>
  <Divider sx={{ my: 2 }} />

<Box mt={2}>
  <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
  CHANGES TO THIS PRIVACY POLICY
  </Typography>
  <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
  This Privacy Policy is published on the app in compliance with Regulation 4 of the (Indian) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011.
  </Typography>
  <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
  We update this Privacy Policy periodically. We will not reduce your rights under this Privacy Policy without your explicit consent. We will notify you of any changes to this Privacy Policy before or at the time of any further collection, use or processing of your personal information/ data. You are requested to visit the website/ app at regular intervals to check and keep yourself informed about the changes that have been made in the Privacy Policy.
  </Typography>
  <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
  The date of last revision appears at the top and all changes take effect immediately upon posting.
  </Typography>
  </Box>

  <Divider sx={{ my: 2 }} />

          <Box mt={2}>

          </Box>
          <Divider sx={{ my: 2 }} />
   <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
      TERMINATION
     </Typography>
     <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
     If your relationship with us ends with us, we will continue to treat and protect your personal information in accordance with this Privacy Policy. That means that we may continue to share your personal information with our partners and affiliates as described above or permitted by law. However, if you notify us of your election not to have us share your personal information with others or store your personal information further, we will honour that request.
     </Typography>
     <Divider sx={{ my: 2 }} />

<Box mt={2}>
<Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
APPLICABLE LAW AND JURISDICTION
</Typography>
<Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
This Privacy Policy shall be governed by, construed and entered in accordance with the laws of the Republic of India, without regard to choice of law or conflict of law provisions thereof.
 

</Typography>
<Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
All disputes with respect to this Privacy Policy shall be brought and heard in the courts situated at Hyderabad, India.
</Typography>
<Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
Our Registered office address is as follows -
</Typography>
</Box>
          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              CONTACT INFORMATION
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Email: pthakur@arthgyan.com
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Address: Plot No C-9, Asha Officers Colony, R K Puram, Hyderabad - 500056
            </Typography>
            
          </Box>
         
           

        </Paper>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
