import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FAQPage = () => {
  return (
    <div
      style={{
        width: "80%",
        margin: "100px auto",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom align="center">
        Frequently Asked Questions
      </Typography>

      {/* Question 1 */}
      <Accordion sx={accordionStyle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" sx={summaryStyle}>
          <Typography sx={{ fontWeight: "bold" }}>What is Arthgyan?</Typography>
        </AccordionSummary>
        <AccordionDetails sx={detailsStyle}>
          <Typography>We are a financial products distributor.</Typography>
        </AccordionDetails>
      </Accordion>

      {/* Question 2 */}
      <Accordion sx={accordionStyle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header" sx={summaryStyle}>
          <Typography sx={{ fontWeight: "bold" }}>Why choose ArthGyan?</Typography>
        </AccordionSummary>
        <AccordionDetails sx={detailsStyle}>
          <Typography>
            ArthGyan is one of India's fastest-growing digital platforms for personal finance products like banking, investment, and insurance. 
            We empower users and help them achieve financial independence.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Question 3 */}
      <Accordion sx={accordionStyle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header" sx={summaryStyle}>
          <Typography sx={{ fontWeight: "bold" }}>What services do you offer?</Typography>
        </AccordionSummary>
        <AccordionDetails sx={detailsStyle}>
          <Typography>
            ArthGyan offers multiple services like banking, investment, insurance, and service benefits. 
            In banking products, we offer credit cards, loans, and accounts. 
            In investment services, we offer mutual funds and FDs. 
            In insurance, we offer different types of insurance like vehicle insurance, life insurance, and health insurance.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Question 4 (Corrected) */}
      <Accordion sx={accordionStyle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4a-content" id="panel4a-header" sx={summaryStyle}>
          <Typography sx={{ fontWeight: "bold" }}>Are there charges or fees involved for registering with us?</Typography>
        </AccordionSummary>
        <AccordionDetails sx={detailsStyle}>
          <Typography>
            ArthGyan is completely free of cost for you to register. 
            We charge a nominal fee to mutual fund companies for the services we provide.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Question 5 */}
      <Accordion sx={accordionStyle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5a-content" id="panel5a-header" sx={summaryStyle}>
          <Typography sx={{ fontWeight: "bold" }}>
            I have more questions about the program that have not been answered here – what do I do?
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={detailsStyle}>
          <Typography>
            Email us your queries at <b>support@arthgyan.com</b>. We would be happy to assist you. 
            Please note that the average response time for all queries is between 48 to 72 hours.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

// Styles
const accordionStyle = {
  border: "1px solid #ccc",
  borderRadius: "8px",
  marginBottom: "10px",
  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#f9f9f9",
};

const summaryStyle = {
  backgroundColor: "#e3f2fd",
  "& .MuiAccordionSummary-content": {
    margin: "0px",
  },
};

const detailsStyle = {
  padding: "15px",
  backgroundColor: "#fff",
};

// Responsive Styles
const responsiveStyles = {
  "@media (max-width: 600px)": {
    width: "90%",
    margin: "50px auto",
  },
  "@media (max-width: 400px)": {
    width: "100%",
    margin: "30px auto",
  },
};

export default FAQPage;
